export class UserInfo {
    Id: string = null;
    FullName: string = null;
    Email: string = null;
    FinancialInstitutionDomainName: string = null;
    IsSysAdmin: boolean = false;
    IsSecurityAdmin: boolean = false;
    IsBHCAdmin: boolean = false;
    SuperWriter: boolean = false;
    SuperReader: boolean = false;
}