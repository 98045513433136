import { IDbStyleRowSecurity, IRowSecurity } from "@interfaces/row-security.interface";

export class RowSecurityModel implements IRowSecurity {
    Id: string = null;
    ItemId: string = null;
    CanRead: boolean = true;
    CanWrite: boolean = true;
    CanDelete: boolean = true;
}

export class DbStyleRowSecurityModel implements IDbStyleRowSecurity {
    Id: string = null;
    DeniedReadAccess: boolean = true;
    DeniedWriteAccess: boolean = true;
    DeniedDeleteAccess: boolean = true;
}
