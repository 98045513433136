import { Injectable } from "@angular/core";
import { UnificationAPIService } from "./unification.api.service";
import { UserPropertyDto, UserPropertyManager } from "./user-property-manager.service";

export class QueryListPreferenceModel {
    Query: string;
    QueryId: string;
}

@Injectable()
export class QueryListPreferenceManager {
    private queryListPreferenceKey: string = "UserQueryListSetting";
    protected queryListPreferences: Array<QueryListPreferenceModel>;

    constructor(
        protected baseService: UnificationAPIService,
        protected userPropertyManager: UserPropertyManager
    ) {
    }

    async getQueryListPreferenceAsync(queryModuleDataProviderCode: string): Promise<QueryListPreferenceModel> {
        if (!queryModuleDataProviderCode) {
            throw new TypeError('A null or undefined queryModuleDataProviderCode value was passed, ex: getQueryListPreference(queryModuleDataProviderCode=null || undefined)');
        }

        // Ensure the global queryListPreferences variable is set before doing anything
        await this.getAllQueryListPreferencesAsync();

        // Now that we've made sure we have the current list of queryListPreferences, pull the requested one,
        // creating a new one if not found, and send it back to the caller
        let currentQueryListPreference: QueryListPreferenceModel = this.queryListPreferences.find(x => x.Query === queryModuleDataProviderCode);

        if (!currentQueryListPreference) {
            currentQueryListPreference = <QueryListPreferenceModel>{
                Query: queryModuleDataProviderCode,
                QueryId: null
            };

            this.queryListPreferences.push(currentQueryListPreference);
        }

        return Object.assign({}, currentQueryListPreference);
    }

    async setQueryListPreferenceAsync(modifiedQueryListPreference: QueryListPreferenceModel): Promise<void> {
        if (!modifiedQueryListPreference) {
            throw new TypeError('A null or undefined modifiedQueryListPreference object was passed, ex: setQueryListPreference(modifiedQueryListPreference=null || undefined)');
        }

        // Ensure the global queryListPreferences variable is set before doing anything
        await this.getAllQueryListPreferencesAsync();

        // Now that we've made sure we have the current list of queryListPreferences, find the requested one to
        // update's location in the array and replace it with the new one and save the updated list.
        let queryListPreferenceIndex: number = this.queryListPreferences.findIndex(x => x.Query === modifiedQueryListPreference.Query);

        this.queryListPreferences.splice(queryListPreferenceIndex, 1, modifiedQueryListPreference);

        await this.userPropertyManager.setUserPropertyAsync<Array<QueryListPreferenceModel>>(this.queryListPreferenceKey, this.queryListPreferences);
    }

    private async getAllQueryListPreferencesAsync(): Promise<void> {
        if (this.queryListPreferences) return;

        const queryListPreferenceUserProperty: UserPropertyDto = await this.userPropertyManager.getUserPropertyAsync(this.queryListPreferenceKey);

        if (!queryListPreferenceUserProperty.PropertyValue) {
            this.queryListPreferences = new Array<QueryListPreferenceModel>();
        } else {
            this.queryListPreferences = JSON.parse(queryListPreferenceUserProperty.PropertyValue);
        }
    }
}