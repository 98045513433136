import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, empty as observableEmpty, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppSettings } from '../../app.globals';
import { AppFunctions } from '@shared/app.functions';
import { NotificationService } from './notification.service';
import { PubSubMessageTypes, PubSubService } from './pubsub.service';
import { UserModel as CurrentUserModel } from '@shared/models/user-model.model';

import * as moment from 'moment';
import { AppSettingsModel } from '../models/app-settings.model';
import {
    ISysPropForm,
    ISysPropSection
} from "../../crm/clients/collaterals/appraisals/collateral-property-valuations.api.service";
import { UserPermissions } from '../common/security/user-permissions.model';
import { ClientSectionsSecurity } from '../common/security/client-sections-security.model';

@Injectable()
export class UnificationAPIService implements OnDestroy {
    public apiURL(): string { return `${AppSettings.API_BASE_URL}odata/`; }
    public complexApiURL(): string { return `${AppSettings.API_BASE_URL}api/`; }
    public reportingApiURL(): string { return `${AppSettings.REPORTAPI_BASE_URL}`; }

    private AUTH_TOKEN: string = 'auth_token';
    private REFRESH_TOKEN: string = 'refresh_token';
    private TOKEN_EXPIRES_AT: string = 'expires_at';
    private CURRENT_USER: string = 'current_user';
    private CURRENT_USER_CLIENT_SECURITY: string = 'current_user_client_security'
    private APP_SETTINGS: string = 'app_settings';
    private refreshTokenSubscription: Subscription;
    private refreshTokenTimerRequested: boolean = false;
    private displayingNotification: boolean = false;
    private displayingLoggedOutNotification: boolean = false;
    private logoutTimerId = null;
    private sessionTimeoutWarningMinutes: number = 10;
    private CURRENT_USER_PERMISSIONS: string = 'current_user_permissions';

    get authToken(): string { return sessionStorage.getItem(this.AUTH_TOKEN); }
    set authToken(value: string) { sessionStorage.setItem(this.AUTH_TOKEN, value); }
    get refreshToken(): string { return sessionStorage.getItem(this.REFRESH_TOKEN); }
    set refreshToken(value: string) { sessionStorage.setItem(this.REFRESH_TOKEN, value); }
    get appSettings(): string { return sessionStorage.getItem(this.APP_SETTINGS); }
    set appSettings(value: string) { sessionStorage.setItem(this.APP_SETTINGS, value); }
    get parsedAppSettings(): AppSettingsModel { return JSON.parse(this.appSettings); }
    /** @deprecated Use parsedCurrentUser */
    get currentUser(): string { return sessionStorage.getItem(this.CURRENT_USER); }
    set currentUser(value: string) { sessionStorage.setItem(this.CURRENT_USER, value); }
    get parsedCurrentUser(): CurrentUserModel { return JSON.parse(this.currentUser); }
    get currentUserClientSecurity(): string { return sessionStorage.getItem(this.CURRENT_USER_CLIENT_SECURITY); }
    set currentUserClientSecurity(value: string) { sessionStorage.setItem(this.CURRENT_USER_CLIENT_SECURITY, value); }
    get parsedUserClientSecurity(): ClientSectionsSecurity { return JSON.parse(this.currentUserClientSecurity); }
    /** @deprecated Use parsedUserPermissions */
    get currentUserPermissions(): any { return sessionStorage.getItem(this.CURRENT_USER_PERMISSIONS); }
    set currentUserPermissions(value: any) { sessionStorage.setItem(this.CURRENT_USER_PERMISSIONS, value); }
    get parsedUserPermissions(): UserPermissions { return JSON.parse(this.currentUserPermissions) };
    get tokenExpiresAt(): string { return sessionStorage.getItem(this.TOKEN_EXPIRES_AT); }
    set tokenExpiresIn(seconds: number) {
        if (!this.displayingLoggedOutNotification) {
            let now = moment.utc();
            now.add(seconds, 'seconds');
            sessionStorage.setItem(this.TOKEN_EXPIRES_AT, now.toISOString());
            this.startLogoutInterval();
        }
    }
    get headers(): HttpHeaders {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Accept', 'application/json');
        let token = this.authToken;
        if (token) {
            headers = headers.append('Authorization', `Bearer ${token}`);
        }
        return headers;
    }
    get xmlHeaders(): HttpHeaders {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'text/xml');
        headers = headers.append('Accept', 'text/xml');
        let token = this.authToken;
        if (token) {
            headers = headers.append('Authorization', `Bearer ${token}`);
        }
        return headers;
    }
    get formHeaders(): HttpHeaders {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
        headers = headers.append('Accept', 'application/json');
        return headers;
    }

    constructor(
        private readonly http: HttpClient,
        private readonly notificationService: NotificationService,
        private readonly router: Router,
        private readonly pubSubService: PubSubService) {
    }

    ngOnDestroy() {
        if (this.refreshTokenSubscription)
            this.refreshTokenSubscription.unsubscribe();
    }

    public clearAuthToken() {
        sessionStorage.removeItem(this.AUTH_TOKEN);
        sessionStorage.removeItem(this.REFRESH_TOKEN);
        sessionStorage.removeItem(this.TOKEN_EXPIRES_AT);
        sessionStorage.removeItem(this.CURRENT_USER);
        sessionStorage.removeItem(this.CURRENT_USER_CLIENT_SECURITY);
    }

    public isLoggedOut(): boolean {
        let result = true;
        let logoutAt = this.tokenExpiresAt;
        if (logoutAt) {
            let logoutAtAsMoment = moment.utc(logoutAt, moment.ISO_8601);
            let nowAsUtc = moment.utc();
            if (nowAsUtc.isBefore(logoutAtAsMoment)) {
                result = false;
            }
        }
        return result;
    }

    private checkToClearLogoutInterval() {
        let logoutAt = this.tokenExpiresAt;
        if (logoutAt === null) {
            this.stopLogoutInterval();
        }
        return logoutAt;
    }

    private showLoggedOutMessage() {
        if (this.displayingLoggedOutNotification) {
            return;
        }
        if (this.tokenExpiresAt !== null) {
            this.stopLogoutInterval();
            this.notificationService.resetNotification();
            this.displayingNotification = false;
            var self = this;
            this.notificationService.error('You have been logged out due to inactivity.').then(() => {
                self.displayingLoggedOutNotification = false;
            });
            this.displayingLoggedOutNotification = true;

            this.clearAuthToken();  // ensure canDeactivate guard knows we are logged out
            this.router.navigateByUrl('login');
        }
    }

    private checkTimeout() {
        let logoutAt = this.checkToClearLogoutInterval();
        if (this.displayingLoggedOutNotification || logoutAt === null) {
            return;
        }
        if (this.isLoggedOut()) {
            this.showLoggedOutMessage();
            return;
        }
        if (this.displayingNotification) {
            return; // already showing the warning
        }
        if (this.sessionTimeoutWarningMinutes > 0) {
            let warningAt = moment.utc(logoutAt).subtract(this.sessionTimeoutWarningMinutes, 'minutes');
            let logoutAtFormatted = moment(logoutAt).format('h:mm:ss A');
            let nowAsMoment = moment.utc();
            if (nowAsMoment.isSameOrAfter(warningAt)) {
                this.displayingNotification = true;
                this.notificationService.logoutWarning(() => { this.refreshLogin() }, `You will automatically be logged out at ${logoutAtFormatted} Click Refresh to continue your session.`);
            }
        }
    }

    private refreshLogin() {
        this.displayingNotification = false;
        if (this.tokenExpiresAt !== null) {
            this.callRefreshToken();
        }
    }

    public startLogoutInterval(): void {
        if (!this.logoutTimerId) {
            this.logoutTimerId = setInterval(() => this.checkTimeout(), 60000); // check once a minute to see if we are getting close to timeout
            this.getSessionTimeoutWarning();
        }
    }

    public stopLogoutInterval(): void {
        if (this.logoutTimerId) {
            clearInterval(this.logoutTimerId);
            this.logoutTimerId = null;
        }
    }

    private getSessionTimeoutWarning(): void {
        let url: string = this.apiURL() + 'SystemProperties?$filter=Name eq \'SessionTimeoutWarning\'&$select=Value';
        this.get(url).subscribe((result: any) => {
            if (result.value.length > 0) {
                this.sessionTimeoutWarningMinutes = parseInt(result.value[0].Value);
                if (isNaN(this.sessionTimeoutWarningMinutes)) {
                    this.sessionTimeoutWarningMinutes = 10;
                }
            }
        });
    }

    public getFile<S>(url: string, body: any, customHeaders: HttpHeaders = null): Observable<S> {
        let options = {
            headers: (customHeaders ? customHeaders : this.headers),
            observe: 'response' as 'body',
            responseType: 'blob' as 'json',
        };
        return this.http.post<S>(url, body, options);
    }

    // This function is a work-around to an Angular bug for setting responseType. Cannot use generics.
    public getXml<T>(url: string, suppressError: boolean = false): Observable<T> {
        let headers = this.xmlHeaders;
        let options = {
            headers: headers,
            responseType: 'text' as 'text' // Must be as 'text'
        };
        // Cannont use the generic .get<T>
        let o: Observable<any> = this.http.get(url, options);
        return o.pipe(
            map(r => r,
                catchError(e => this.handleError(e, suppressError)))
        );
    }

    public buildUrl(url: string, params: {} = {}): string {
        const firstCharacter = url.includes('?') ? '&' : '?';
        const query = Object.getOwnPropertyNames(params)
            .filter(p => params[p] !== undefined && params[p] !== null)
            .map(p => {
                return {
                    key: encodeURIComponent(p),
                    value: encodeURIComponent(params[p] + '')
                };
            })
            .map(p => `${p.key}=${p.value}`)
            .join('&');
        return `${url}${firstCharacter}${query}`;
    }

    public get<T>(url: string, customHeaders: HttpHeaders = null, suppressError: boolean = false, refreshLoginToken: boolean = true): Observable<T> {
        // Only try and refresh the token once every 30 seconds if requested
        if (!this.refreshTokenTimerRequested && refreshLoginToken) {
            this.refreshTokenTimerRequested = true;
            setTimeout(() => this.callRefreshToken(), 30000);
        }
        let options = { headers: (customHeaders ? customHeaders : this.headers) };
        return this.http.get<T>(url, options).pipe(map((response: T) => { return response; }, catchError((error: Response) => this.handleError(error, suppressError))));
    }

    public postWithOptions(url: string, body: any = '', options: any, suppressError: boolean = false) {
        return this.http.post(url, body, options).pipe(map((response) => { return response; }, catchError((error: Response) => this.handleError(error, suppressError))));
    }

    public post<T>(url: string, body: any = '', customHeaders: HttpHeaders = null, suppressError: boolean = false): Observable<T> {
        let options = { headers: (customHeaders ? customHeaders : this.headers) };
        return this.http.post<T>(url, body, options).pipe(map((response: T) => { return response; }, catchError((error: Response) => this.handleError(error, suppressError))));
    }

    public patch<T>(url: string, body: any = '', customHeaders: HttpHeaders = null, suppressError: boolean = false): Observable<T> {
        let options = { headers: (customHeaders ? customHeaders : this.headers) };
        return this.http.patch<T>(url, body, options).pipe(map((response: T) => { return response; }, catchError((error: Response) => this.handleError(error, suppressError))));
    }

    public put<T>(url: string, body: any = '', customHeaders: HttpHeaders = null, suppressError: boolean = false): Observable<T> {
        let options = { headers: (customHeaders ? customHeaders : this.headers) };
        return this.http.put<T>(url, body, options).pipe(map((response: T) => { return response; }, catchError((error: Response) => this.handleError(error, suppressError))));
    }

    public delete<T>(url: string, customHeaders: HttpHeaders = null, suppressError: boolean = false): Observable<T> {
        let options = { headers: (customHeaders ? customHeaders : this.headers) };
        return this.http.delete<T>(url, options).pipe(map((response: T) => { return response; }, catchError((error: Response) => this.handleError(error, suppressError))));
    }

    private checkForInnerMessage(error) {
        let errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        if (error._body) {
            let body = JSON.parse(error._body);
            if (body.InnerError && body.InnerError.Message) {
                errMsg = body.InnerError.Message;
            }
            if (body.DetailedMessage || body.SimpleMessage) {
                errMsg = (body.DetailedMessage) ? body.DetailedMessage : body.SimpleMessage;
            }
            if (body.Code != null) {
                errMsg += ` (${body.Code})`;
            }
        }
        return errMsg;
    }

    public callRefreshToken() {
        if (!this.refreshToken || this.isLoggedOut()) {
            this.refreshTokenTimerRequested = false;
            return;
        }
        let body = `refresh_token=${this.refreshToken}&grant_type=refresh_token&scope=nextgenui`;
        let url = `${AppSettings.API_BASE_URL}token`;
        let headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${this.authToken}`
        });
        let post = this.post(url, body, headers);
        this.refreshTokenSubscription = post
            .subscribe((context: any) => {
                this.authToken = context.access_token;
                this.refreshToken = context.refresh_token;
                this.tokenExpiresIn = context.expires_in;
                this.refreshTokenTimerRequested = false;
                this.checkToClearLogoutInterval();
                this.pubSubService.publishMessage({ MessageType: PubSubMessageTypes.LOGIN_REFRESHED, MessageData: {} });
            });
    }

    private handleError(error: Response, suppressError: boolean = false): any {
        let errMsg = this.checkForInnerMessage(error);
        console.log(error.url);
        console.error(errMsg);
        if (this.displayingLoggedOutNotification) {
            return;
        }
        if (error.status === 401) {
            console.error(error);
            this.showLoggedOutMessage();
            return;
        }
        let body;
        if (error.text) {
            body = error.json;
            if (body.Code && body.Code != "100" && body.Title && body.SimpleMessage) {
                this.notificationService.error(body.Title + ' : ' + body.SimpleMessage);
                return observableThrowError("Handled");
            }
            if (body.error) {
                if (body.error === 'invalid_grant') {
                    return; // ignore this, handled in global
                }
                this.notificationService.error(AppFunctions.IsNullOrWhiteSpace(body.error.message) ? body.error : body.error.message);
                return observableEmpty;
            }
        }
        if (!suppressError) {
            this.notificationService.error(errMsg);
        }
        return observableThrowError(errMsg);
    }

    public convertFormToKeyValuePairs(form: ISysPropForm): { [key: string]: string } {
        const o = { ...form.MetaData };
        form.Sections.forEach(section => {
            this.convertSectionToKeyValuePairs(section, o);
        });
        return o;
    }

    private convertSectionToKeyValuePairs(section: ISysPropSection, o: { [key: string]: string }, previousKeys: string[] = []) {
        section.Fields.forEach(field => o[[...previousKeys, section.Key, field.Name].join('/')] = `${field.Value}`);
        section.Sections.forEach(nextSection => this.convertSectionToKeyValuePairs(nextSection, o, [...previousKeys, section.Key]))
    }
}
