import { Injectable } from '@angular/core';
import { UnificationAPIService } from './unification.api.service';

@Injectable()
export class DefaultOpportunitySectionsService {
    private url: string = null;
    constructor(private baseService: UnificationAPIService) {
        this.url = this.baseService.complexApiURL() + 'user';
    }

    public getOpportunitySections(opportunityId: string = '', fromOpportunityType: string = ''): any {
        let OpportunitySectionUrls = [
            { key: 'Activities', url: `clientopportunitiesactivities/${opportunityId}` },
            { key: 'Bank Assignments', url: `clientopportunitiesbankassignment/${opportunityId}` },
            { key: 'Bills Due', url: `clientopportunitiesbillsdue/${opportunityId}` },
            { key: 'CECL Calculation', url: `ceclcalculation/${opportunityId}` },
            { key: 'CECL Tiers and Pools', url: `opportunitytiersandpoolshistory/${opportunityId}` },
            { key: 'Comments', url: `clientopportunitiescomment/${opportunityId}` },
            { key: 'Documents Uploaded', url: `documents/${opportunityId}` },
            { key: 'History', url: `clientopportunitieshistory/${opportunityId}` },
            { key: 'Losses and Recoveries', url: `clientopportunitylossrecoverylist/${opportunityId}` }, 
            { key: 'Participation', url: `clientopportunitiesparticipation/${opportunityId}` },
            { key: 'Pricing Details', url: `clientopportunitydetaillist/${opportunityId}` },
            { key: 'Relationship Ties', url: `clientopportunitiesrelationshiptieslist/${opportunityId}` },
            { key: 'Risk Ratings', url: `opportunityriskratinglist/${opportunityId}` },
            { key: 'Securing Collateral', url: `clientopportunitiessecuringcollateral/${opportunityId}` },
            { key: 'System Audit History', url: `clientopportunitiessystemaudithistorylist/${opportunityId}` },
            { key: 'Team Members', url: `clientopportunitiesteammemberlist/${opportunityId}` },
            { key: 'Tracking Items', url: `clientopportunitiestrackingitemlist/${opportunityId}/${fromOpportunityType}` },           
           
        ];
        return OpportunitySectionUrls;
    }

}