import { Injectable } from "@angular/core";
import { UnificationAPIService } from "./unification.api.service";
import { UserPreferenceManager, UserPreferenceModel } from "./user-preference-manager.service";
import { UserPropertyDto, UserPropertyManager } from "./user-property-manager.service";

export class TablePreferenceModel {
    TableName: string;
    PageLength: number;
    IsExpanded: boolean;
    Filter: string;
}

@Injectable()
export class TablePreferenceManager {
    private tablePreferenceKey: string = 'TableSettingPreference';
    protected tablePreferences: Array<TablePreferenceModel>;

    constructor(
        protected baseService: UnificationAPIService,
        protected userPreferenceManager: UserPreferenceManager,
        protected userPropertyManager: UserPropertyManager
    ) {
    }

    async getTablePreferenceAsync(tableName: string): Promise<TablePreferenceModel> {
        if (!tableName) {
            throw new TypeError('A null or undefined tableName value was passed, ex: getTablePreference(tableName=null || undefined)');
        }

        // Ensure the global tablePreferences variable is set before doing anything
        await this.getAllTablePreferencesAsync();

        // Now that we've made sure we have the current list of tablePreferences, pull the requested one,
        // creating a new one if not found, and send it back to the caller
        let currentTablePreference = this.tablePreferences.find(x => x.TableName === tableName);

        if (!currentTablePreference) {
            currentTablePreference = <TablePreferenceModel>{
                TableName: tableName,
                PageLength: await this.getDefaultPageLengthAsync(),
                IsExpanded: await this.getDefaultExpandValueAsync()
            };

            this.tablePreferences.push(currentTablePreference);
        }

        return Object.assign({}, currentTablePreference);
    }

    async setTablePreferenceAsync(modifiedTablePreference: TablePreferenceModel): Promise<void> {
        if (!modifiedTablePreference) {
            throw new TypeError('A null or undefined modifiedTablePreference object was passed, ex: setTablePreference(modifiedTablePreference=null || undefined)');
        }
        this.userPropertyManager.showoverrideTableSetting = false;

        // Ensure the global tablePreferences variable is set before doing anything
        await this.getAllTablePreferencesAsync();

        // Now that we've made sure we have the current list of tablePreferences, find the requested one to
        // update's location in the array and replace it with the new one and save the updated list.
        const currentTablePreferenceIndex: number = this.tablePreferences.findIndex(x => x.TableName === modifiedTablePreference.TableName);

        this.tablePreferences.splice(currentTablePreferenceIndex, 1, modifiedTablePreference);

        await this.userPropertyManager.setUserPropertyAsync<Array<TablePreferenceModel>>(this.tablePreferenceKey, this.tablePreferences);
    }

    private async getDefaultPageLengthAsync(): Promise<number> {
        const userPreference: UserPreferenceModel = await this.userPreferenceManager.getUserPreferenceAsync();

        return userPreference.NumberOfRowsPreference ?? 10;
    }

    private async getDefaultExpandValueAsync(): Promise<boolean> {
        const userPreference: UserPreferenceModel = await this.userPreferenceManager.getUserPreferenceAsync();

        return userPreference.TreeExpandPreference ?? false;
    }

    private async getAllTablePreferencesAsync(): Promise<void> {

        if (this.userPropertyManager.showoverrideTableSetting && this.tablePreferences) {
              return;
        }
       

        const tablePreferenceUserProperty: UserPropertyDto = await this.userPropertyManager.getUserPropertyAsync(this.tablePreferenceKey);

        if (!tablePreferenceUserProperty.PropertyValue) {
            this.tablePreferences = new Array<TablePreferenceModel>();
        } else {
            this.tablePreferences = JSON.parse(tablePreferenceUserProperty.PropertyValue);
        }
    }
}