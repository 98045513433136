import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export abstract class ErrorInterceptorBase implements HttpInterceptor {

    constructor() {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((responseError: HttpErrorResponse) => {
                if (this.canHandleError(req, responseError)) {
                    return throwError(this.handleError(req, responseError));
                }

                // If we don't re-throw the error just like this 'return throwError(error);'.
                // Subscribers with 'error =>' won't get triggered.
                return throwError(responseError);
            }));
    }

    abstract canHandleError(req: HttpRequest<any>, responseError: HttpErrorResponse): boolean;
    abstract handleError(req: HttpRequest<any>, responseError: HttpErrorResponse): HttpErrorResponse;
}
