import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppFunctions } from '../app.functions';
import { ModalService } from "shared/modals/modal.service";
import { NotificationModal } from "shared/services/notification-modal.component";

export interface INotificationResponse {
    ok?: boolean;
    cancel?: boolean;
    other?: boolean;
}

export interface INotificationOptions {
    okTitle?: string;
}

export interface INotificationModal {
    messageType?: 'error' | 'question';
    message?: string;
    okButtonText?: string;
    okButtonAction?: () => void;
    otherButtonText?: string;
    otherButtonAction?: () => void;
    cancelButtonText?: string;
    cancelButtonAction?: () => void;
}

export const OkNotificationResponse: INotificationResponse = {ok: true};
export const CancelNotificationResponse: INotificationResponse = {cancel: true};
export const OtherNotificationResponse: INotificationResponse = {other: true};

@Injectable()
export class NotificationService {
    private subject = new Subject<any>();
    showNotification: boolean = false;
    notificationType: '' | 'toast' = '';
    messageType: '' | 'success' | 'error' | 'question' = '';
    message: string = '';
    public okBtn: string = '';
    public otherBtn: string = '';
    public cancelBtn: string = '';
    private toastDelay: any;

    constructor(private readonly modalService: ModalService) {

    }

    private currentTime(): string {
        return new Date().toLocaleTimeString();
    }

    public getBtnText(): Observable<any> {
        return this.subject.asObservable();
    }

    public resetNotification() {
        this.showNotification = false;
        clearTimeout(this.toastDelay);
        this.notificationType = this.messageType = this.message = this.cancelBtn = this.okBtn = this.otherBtn = '';
    }

    public success(text: string, delayAmount: number = 6000): void {
        this.showNotification = true;
        this.notificationType = 'toast';
        this.messageType = 'success';
        this.message = text + ' @ ' + this.currentTime();
        this.toastDelay = setTimeout(() => {
            this.resetNotification()
        }, delayAmount);
    }

    public errorMessage(text: string, delayAmount: number = 6000): void {
        this.showNotification = true;
        this.notificationType = 'toast';
        this.messageType = 'error';
        this.message = text;
        this.toastDelay = setTimeout(() => {
            this.resetNotification()
        }, delayAmount);
    }

    public error(text: string): Promise<INotificationResponse> {
        return new Promise<INotificationResponse>((accept, _) => {
            this.modalService.show(NotificationModal, {
                notification: true,
                initialState: {
                    messageType: 'error',
                    okButtonText: 'OK',
                    message: AppFunctions.stringReplaceAll(text, "\r\n", "<br />"),
                    okButtonAction: () => accept(OkNotificationResponse),
                    cancelButtonAction: () => accept(CancelNotificationResponse),
                    otherButtonAction: () => accept(OtherNotificationResponse)
                }
            });
        });
    }

    public info(text: string): Promise<INotificationResponse> {
        return new Promise<INotificationResponse>((accept, _) => {
            this.modalService.show(NotificationModal, {
                notification: true,
                initialState: {
                    okButtonText: 'OK',
                    message: text,
                    okButtonAction: () => accept(OkNotificationResponse),
                    cancelButtonAction: () => accept(CancelNotificationResponse),
                    otherButtonAction: () => accept(OtherNotificationResponse)
                }
            });
        });
    }

    public confirmPromiseWithoutButtonText(text: string): Promise<INotificationResponse> {
        return new Promise<INotificationResponse>((accept, _) => {
            this.modalService.show(NotificationModal, {
                notification: true,
                initialState: {
                    messageType: 'question',
                    okButtonText: 'OK',
                    message: text,
                    okButtonAction: () => accept(OkNotificationResponse),
                    cancelButtonAction: () => accept(CancelNotificationResponse),
                    otherButtonAction: () => accept(OtherNotificationResponse)
                }
            });
        });
    }

    public confirmPromise(text: string): Promise<INotificationResponse> {
        return new Promise<INotificationResponse>((accept, _) => {
            this.modalService.show(NotificationModal, {
                notification: true,
                initialState: {
                    messageType: 'question',
                    okButtonText: 'Yes',
                    cancelButtonText: 'No',
                    message: text,
                    okButtonAction: () => accept(OkNotificationResponse),
                    cancelButtonAction: () => accept(CancelNotificationResponse),
                    otherButtonAction: () => accept(OtherNotificationResponse)
                }
            });
        });
    }

    public confirmRejectPromise(text: string): Promise<INotificationResponse> {
        return new Promise<INotificationResponse>((accept, _) => {
            this.modalService.show(NotificationModal, {
                notification: true,
                wideNotification: true,
                initialState: {
                    messageType: 'question',
                    okButtonText: 'Approve',
                    cancelButtonText: 'Cancel',
                    otherButtonText: 'Reject',
                    message: text,
                    okButtonAction: () => accept(OkNotificationResponse),
                    cancelButtonAction: () => accept(CancelNotificationResponse),
                    otherButtonAction: () => accept(OtherNotificationResponse)
                }
            });
        });
    }

    public confirmUnsaved(text: string, hasSaveAndContinue: boolean = false, options: INotificationOptions = null): Promise<INotificationResponse> {
        return new Promise<INotificationResponse>((accept, _) => {
            this.modalService.show(NotificationModal, {
                notification: true,
                wideNotification: hasSaveAndContinue,
                initialState: {
                    messageType: 'question',
                    okButtonText: options?.okTitle ?? 'Continue Without Saving',
                    cancelButtonText: 'Cancel',
                    otherButtonText: hasSaveAndContinue
                        ? 'Save and Continue'
                        : null,
                    message: text,
                    okButtonAction: () => accept(OkNotificationResponse),
                    cancelButtonAction: () => accept(CancelNotificationResponse),
                    otherButtonAction: () => accept(OtherNotificationResponse)
                }
            });
        });
    }

    public logoutWarning(callback: () => void, text: string): void {
        this.modalService.show(NotificationModal, {
            notification: true,
            initialState: {
                messageType: 'question',
                okButtonText: 'Refresh',
                message: text,
                okButtonAction: () => callback()
            }
        });
    }

    public confirm(callback: () => void, text: string, rejectCallback: () => void = null): void {
        this.modalService.show(NotificationModal, {
            notification: true,
            initialState: {
                messageType: 'question',
                okButtonText: 'Yes',
                cancelButtonText: 'No',
                message: text,
                okButtonAction: () => callback(),
                cancelButtonAction: rejectCallback
                    ? () => rejectCallback()
                    : null
            }
        });
    }

    public confirmAndRedirect(callback: () => void, text: string): void {
        this.modalService.show(NotificationModal, {
            notification: true,
            initialState: {
                messageType: 'error',
                okButtonText: 'OK',
                message: text,
                okButtonAction: () => callback()
            }
        });
    }

    public ContinueAndCancel(callback: () => void, callbackReject: () => void, text: string): void {
        this.modalService.show(NotificationModal, {
            notification: true,
            initialState: {
                messageType: 'question',
                okButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                message: text,
                okButtonAction: () => callback(),
                cancelButtonAction: () => callbackReject()
            }
        });
    }

    public ContinueAndCancelAsWarning(callback: () => void, callbackReject: () => void, text: string): void {
        this.modalService.show(NotificationModal, {
            notification: true,
            initialState: {
                messageType: 'error',
                okButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                message: text,
                okButtonAction: () => callback(),
                cancelButtonAction: () => callbackReject()
            }
        });
    }

    public confirmAsWarning(callback: () => void, text: string): void {
        this.modalService.show(NotificationModal, {
            notification: true,
            initialState: {
                messageType: 'error',
                okButtonText: 'Yes',
                cancelButtonText: 'No',
                message: text,
                okButtonAction: () => callback()
            }
        });
    }
}
