import { HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorInterceptorBase } from './error-interceptor.base';

@Injectable()
export class NextGenForcePasswordChangeErrorInterceptor extends ErrorInterceptorBase {

    constructor(private router: Router) {
        super();
    }

    public canHandleError(req: HttpRequest<any>, responseError: HttpErrorResponse): boolean {
        let canHandle: boolean = false;

        if (req.body === null || req.body === undefined || !req.body.split)
            return canHandle;

        let requestBody = req.body.split('&');
        let grantType = requestBody.find(x => x.includes('grant_type'));

        if (responseError.error &&
            responseError.error.error &&
            responseError.error.error === 'Please change your password.' &&
            grantType &&
            grantType.split('=').slice(-1)[0] === 'nextgen') {
            canHandle = true;
        }

        return canHandle;
    }

    public handleError(req: HttpRequest<any>, error: HttpErrorResponse): HttpErrorResponse {
        let requestBody = req.body.split('&');
        let userName = requestBody.find(x => x.includes('username')).split('=').slice(-1)[0];

        this.router.navigate(['/forcechangepassword', userName]);

        return error;
    }
}