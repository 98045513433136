import { CacheService } from '@shared/services/cache-service';
import { AppFunctions } from "../app.functions";

export function Cacheable() {
    return function (target: any, propertyKey: string, descriptor: TypedPropertyDescriptor<any>) {
        const originalMethod = descriptor.value; // save a reference to the original method

        // ClassName.MethodName style cacheKey
        let cacheKey = `${target.constructor.name}.${propertyKey}`;

        // NOTE: Do not use arrow syntax here. Use a function expression in 
        // order to use the correct value of `this` in this method (see notes below)
        descriptor.value = function (...args: any[]) {
            // ClassName.MethodName.ArgumentHashCode style cacheKey
            const objectString = '[object Object]';
            const joinedArgumentsString = args.join();
            if (joinedArgumentsString.includes(objectString)) {
                throw new TypeError('@Cacheable should only be used on methods without arguments or ' +
                    'where arguments are all primitive types and arrays.\n\n' +
                    'If you look at the following examples you can see that objects result in the same string:\n' +
                    '[1, false, \'hello\', [3, 4]].join()\n' +
                    '"1,false,hello,3,4"\n' +
                    '[1, false, \'hello\', [3, 4], {}].join()\n' +
                    '"1,false,hello,3,4,[object Object]"\n' +
                    '[1, false, \'hello\', [3, 4], {}, {}].join()\n' +
                    '"1,false,hello,3,4,[object Object],[object Object]"');
            }
            const argsKey = AppFunctions.getStringHashCode(joinedArgumentsString).toString();
            const cacheKey = `${target.constructor.name}.${propertyKey}.${argsKey}`;
            return CacheService.getOrSet(cacheKey, originalMethod.apply(this, args));
        };

        return descriptor;
    };
}
