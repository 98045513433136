import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ErrorMessage} from './models/error-message-model';
import {ExceptionMessage} from './models/exception-message-model';
import {NotificationService} from './services/notification.service';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private notificationService: NotificationService;

    constructor(private injector: Injector) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Skip this if it has been asked of us
        if (req.headers.has(InterceptorSkipHeader)) {
            const headers = req.headers.delete(InterceptorSkipHeader);
            return next.handle(req.clone({headers}));
        }
        return next.handle(req).pipe(
            catchError((error: any) => {
                this.processError(error);
                // If we don't re-throw the error just like this 'return throwError(error);'.
                // Subscribers with 'error =>' won't get triggered.
                return throwError(error);
            }));
    }

    private async processError(errorResponse: HttpErrorResponse) {
        try {
            let exceptionMessage: ExceptionMessage = null;
            let errorMessage: ErrorMessage = null;
            let notificationMessage: string = null;
            // Initialize the Notification Services if it is not already initialized.
            if (!this.notificationService) {
                this.notificationService = this.injector.get(NotificationService);
            }
            // in the event of a file download, we ask for a blob, and not a JSON response, so check the error for a blob
            if (errorResponse.error instanceof Blob) {
                const blobMessage = await errorResponse.error.text();
                exceptionMessage = JSON.parse(blobMessage) as ExceptionMessage;
            }
                // Because we're stuffing JSON object inside of the HttpErrorResponse.error property
                // we have to interrogate the content in order to determin which class to use.
            // Find errors that contain the 'Code' property.
            else if (errorResponse.error) {
                if (errorResponse.error.Code || errorResponse.error.SimpleMessage) {
                    exceptionMessage = Object.assign(ExceptionMessage, errorResponse.error);
                }
                // Find errors that contain the 'text' property.
                else if (errorResponse.error.text) {
                    exceptionMessage = Object.assign(ExceptionMessage, errorResponse.error.text);
                }
                // Find errors that contain the 'error' property.
                else if (errorResponse.error.error) {
                    errorMessage = Object.assign(ErrorMessage, errorResponse.error);
                } else if (errorResponse.error.Message) {
                    notificationMessage = errorResponse.error.Message;
                }
                // If there is a ModelState property on the object, include the message in those with the error message returned.
                if (errorResponse.error.ModelState) {
                    const modelErrors = Object.keys(errorResponse.error.ModelState).map(function (key) {
                        return errorResponse.error.ModelState[key];
                    });

                    notificationMessage = `${notificationMessage ?? ''}<br/><br/>${modelErrors.join('<br/><br/>')}`;
                }
            }
            // Set the notification message by checking if objects are initialized.
            if (exceptionMessage) {
                notificationMessage = exceptionMessage.SimpleMessage;
            } else if (errorMessage) {
                notificationMessage = errorMessage.error;
            }
            // Display the notification message or a default message.
            if (notificationMessage) {
                if (exceptionMessage && exceptionMessage.LogId) {
                    notificationMessage += `\r\n(${exceptionMessage.LogId})`;
                }

                if (exceptionMessage?.DetailedErrors) {
                    notificationMessage += '<br><br>';
                    exceptionMessage?.DetailedErrors
                        .forEach(m => {
                            notificationMessage += `${m.Message}<br><span style="font-size: 10px; color: darkgrey;">${m.Path.join(' > ')}</span><br><br>`;
                        });
                }

                if (notificationMessage['innererror'] && notificationMessage['innererror']['type'] === 'Microsoft.OData.ODataException') {
                    notificationMessage = `OData: ${notificationMessage['message']}`;
                }

                this.notificationService.error(notificationMessage + '');
                console.error(notificationMessage);
            } else {
                console.error(errorResponse.message);
                this.notificationService.error('An unexpected error occurred.');
            }
        } catch (ex) {
            console.warn("Error when trying to output error.");
            console.error(ex);
        }
    }
}
