import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TINYMCE_SCRIPT_SRC } from "@tinymce/tinymce-angular";

@NgModule({
    imports: [
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        RouterModule,
        CommonModule
    ],
    exports: [
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        RouterModule,
        CommonModule
    ],
    providers: [
        {provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js'}
    ]
})
export class BasicSharedModule {
}
