import { Injectable, TemplateRef } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { ModalOptions } from "ngx-bootstrap/modal/modal-options.class";

export interface IBhModalOptions<T> extends ModalOptions<T> {
    sizeClass?: 'modal-sm' | 'modal-md' | 'modal-lg' | 'modal-xl' | '';
    notification?: boolean;
    wideNotification?: boolean;
    scrollable?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class ModalService {

    constructor(private readonly bsModalService: BsModalService) {
    }

    show<T>(content: string | TemplateRef<any> | {
        new(...args: any[]): T;
    }, config?: IBhModalOptions<T>): BsModalRef<T> {

        // Let us take a copy, I'm not in the habit of mutating things people
        // give me
        const options = {...(config ?? {})};

        if (options.notification) {
            this.addClass(options, 'notification');
            if (options.wideNotification) {
                this.addClass(options, 'modal-third-button');
            } else if (!options.sizeClass) {
                this.addClass(options, 'modal-sm')
            }
            options.scrollable = false;
        }

        this.addClass(options, 'modal-dialog-centered');

        if (options.animated === undefined) {
            options.animated = false;
        }
        if (options.ignoreBackdropClick === undefined) {
            options.ignoreBackdropClick = true;
        }
        if (options.scrollable || options.scrollable === undefined) {
            this.addClass(options, 'modal-dialog-scrollable');
        }
        if (options.sizeClass) {
            this.addClass(options, options.sizeClass);
        }

        return this.bsModalService.show(content, options);
    }

    private addClasses<T>(options: IBhModalOptions<T>, newClasses: string[]) {
        if (!options.class) options.class = '';
        const classes = options.class.split(' ');
        const classesToAdd = newClasses
            .filter(newClass => !classes.includes(newClass));
        if (classesToAdd.length === 0) return;
        options.class = [...classes, ...classesToAdd].join(' ');
    }

    private addClass<T>(options: IBhModalOptions<T>, newClass: string) {
        this.addClasses(options, [newClass]);
    }
}
