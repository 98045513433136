import { Injectable } from "@angular/core";
import { UnificationAPIService } from "./unification.api.service";
import { UserPreferenceManager, UserPreferenceModel } from "./user-preference-manager.service";
import { UserPropertyDto, UserPropertyManager } from "./user-property-manager.service";

export class TreePreferenceModel {
    TreeName: string;
    IsExpanded: boolean;
    Filter: string;
}

@Injectable()
export class TreePreferenceManager {
    private treePreferenceKey: string = 'TreeSettingPreference';
    protected treePreferences: Array<TreePreferenceModel>;

    public constructor(
        protected baseService: UnificationAPIService,
        protected userPreferenceManager: UserPreferenceManager,
        protected userPropertyManager: UserPropertyManager
    ) {
    }

    public async getTreePreferenceAsync(treeName: string): Promise<TreePreferenceModel> {
        if (!treeName) {
            throw new TypeError('A null or undefined treeName value was passed, ex: getTreePreference(treeName=null || undefined)');
        }

        // Ensure the global treePreferences variable is set before doing anything
        await this.getAllTreePreferencesAsync();

        // Now that we've made sure we have the current list of treePreferences, pull the requested one,
        // creating a new one if not found, and send it back to the caller
        let currentTreePreference = this.treePreferences.find(x => x.TreeName === treeName);

        if (!currentTreePreference) {
            currentTreePreference = <TreePreferenceModel>{
                TreeName: treeName,
                IsExpanded: await this.getDefaultExpandValueAsync()
            };

            this.treePreferences.push(currentTreePreference);
        }

        return Object.assign({}, currentTreePreference);
    }

    public async setTreePreferenceAsync(modifiedTreePreference: TreePreferenceModel): Promise<void> {
        if (!modifiedTreePreference) {
            throw new TypeError('A null or undefined modifiedTreePreference object was passed, ex: setTreePreference(modifiedTreePreference=null || undefined)');
        }

        // Ensure the global treePreferences variable is set before doing anything
        await this.getAllTreePreferencesAsync();

        // Now that we've made sure we have the current list of treePreferences, find the requested one to
        // update's location in the array and replace it with the new one and save the updated list.
        const currentTreePreferenceIndex: number = this.treePreferences.findIndex(x => x.TreeName === modifiedTreePreference.TreeName);

        this.treePreferences.splice(currentTreePreferenceIndex, 1, modifiedTreePreference);

        await this.userPropertyManager.setUserPropertyAsync<Array<TreePreferenceModel>>(this.treePreferenceKey, this.treePreferences);
    }

    private async getDefaultExpandValueAsync(): Promise<boolean> {
        const userPreference: UserPreferenceModel = await this.userPreferenceManager.getUserPreferenceAsync();

        return userPreference.TreeExpandPreference ?? false;
    }

    private async getAllTreePreferencesAsync(): Promise<void> {
        if (this.treePreferences) return;

        const treePreferenceUserProperty: UserPropertyDto = await this.userPropertyManager.getUserPropertyAsync(this.treePreferenceKey);

        if (!treePreferenceUserProperty.PropertyValue) {
            this.treePreferences = new Array<TreePreferenceModel>();
        } else {
            this.treePreferences = JSON.parse(treePreferenceUserProperty.PropertyValue);
        }
    }
}