import { map, tap } from "rxjs/operators";
import { UnificationAPIService } from "./unification.api.service";
import { Injectable } from "@angular/core";

export class UserPropertyDto {
    PropertyName: string;
    PropertyValue: string;
}

@Injectable()
export class UserPropertyManager {
    private overrideTableSetting: string = 'overrideTableSettingPrefrence';
    private allUserProperties: Array<UserPropertyDto>;

    get showoverrideTableSetting(): boolean {
        return sessionStorage.getItem(this.overrideTableSetting) === "false";
    }

    set showoverrideTableSetting(value: boolean) {
        sessionStorage.setItem(this.overrideTableSetting, value.toString());
    }

    constructor(
        protected baseService: UnificationAPIService
    ) {

    }

    async getUserPropertyAsync(propertyName: string): Promise<UserPropertyDto> {
        if (!propertyName) {
            throw new TypeError('A null or undefined propertyName value was passed, ex: getUserProperty(propertyName=null || undefined)');
        }

        // Ensure the global allUserProperties variable is set before doing anything
        await this.getAllUserPropertiesAsync();

        // Now that we've made sure we have the current list of allUserProperties, pull the requested one,
        // creating a new one if not found, and send it back to the caller
        let userProperty: UserPropertyDto = this.allUserProperties.find(x => x.PropertyName === propertyName);

        if (!userProperty) {
            userProperty = <UserPropertyDto>{
                PropertyName: propertyName,
                PropertyValue: null
            };
        }

        return Object.assign({}, userProperty);
    }

    async setUserPropertyAsync<T>(propertyName: string, propertyValue: T): Promise<void> {
        if (!propertyName) {
            throw new TypeError('A null or undefined propertyName or propertyValue value was passed, ex: setUserProperty(propertyName=null || undefined, propertyValue=null || undefined)');
        }

        // Ensure the global allUserProperties variable is set before doing anything
        await this.getAllUserPropertiesAsync();

        // Now that we've made sure we have the current list of allUserProperties, find the requested one to
        // update's location in the array and replace it with the new one and save the updated list.
        let userPropertyIndex: number = this.allUserProperties.findIndex(x => x.PropertyName === propertyName);
        const userProperty: UserPropertyDto = <UserPropertyDto>{
            PropertyName: propertyName,
            PropertyValue: JSON.stringify(propertyValue)
        };

        this.allUserProperties.splice(userPropertyIndex, 1, userProperty);

        let apiUrl: string = `${this.baseService.complexApiURL()}UserProperties/SaveUserProperty`;

        await this.baseService.post(apiUrl, userProperty).toPromise();
    }

    private async getAllUserPropertiesAsync(): Promise<void> {
        if (this.allUserProperties) return;

        const apiUrl: string = `${this.baseService.complexApiURL()}UserProperties/GetAllUserProperties`;

        await this.baseService.get<UserPropertyDto[]>(apiUrl)
            .pipe(
                map(x => {
                    let userProperties = x.map(y => Object.assign(new UserPropertyDto(), y));

                    return userProperties;
                }),
                tap(x => this.allUserProperties = x)
            )
            .toPromise();
    }
}
