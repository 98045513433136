import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthorizationService } from './authorization.service';
import { UserModel } from './models/user-model.model';
import { UnificationAPIService } from './services/unification.api.service';

@Injectable()
export class AuthorizationGuard implements CanActivate {
    private currentUser: UserModel = new UserModel();

    constructor(private authService: AuthorizationService,
        private router: Router,
        private unificationAPIService: UnificationAPIService, ) { }

    canActivate(
        // Not using but worth knowing about
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        this.currentUser = JSON.parse(this.unificationAPIService.currentUser);
        if (this.authService.isLoggedIn && this.currentUser && this.currentUser != undefined) {
            return true;
        }
        this.router.navigate(['/login']);
        return false;
    }
}
