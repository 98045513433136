import { Injectable } from "@angular/core";
import { UnificationAPIService } from "./unification.api.service";
import { UserPropertyDto, UserPropertyManager } from "./user-property-manager.service";

export class ProfitabilityListPreferenceModel {
    OpportunityId: string;
    ClientTabInfo: TabInfo;
    DealTabInfo: TabInfo;
}

export class TabInfo {
    ShowRelatedItems: boolean;
    OpportunityIds: string[] = [];
    ProductIds: string[] = [];
}


@Injectable()
export class OpportunityProfitabilityPreferenceManager {
    private opportunityProfitabilityPreferenceKey: string = 'OpportunityProfitabilityPreference';
    protected opportunityProfitabilityPreference: Array<ProfitabilityListPreferenceModel>;

    constructor(
        protected baseService: UnificationAPIService,
        protected userPropertyManager: UserPropertyManager
    ) {
    }

    async getOpportunityProfitabilityPreferenceAsync(opportunityId: string): Promise<ProfitabilityListPreferenceModel> {
        if (!opportunityId) {
            throw new TypeError('A null or undefined opportunityId value was passed, ex: getOpportunityProfitabilityPreference(opportunityId=null || undefined)');
        }

        await this.pullOpportunityPreferenceRecordAsync();

        let opportunityProfitabilityPreference: ProfitabilityListPreferenceModel = this.opportunityProfitabilityPreference?.find(x => x.OpportunityId === opportunityId);

        if (!opportunityProfitabilityPreference) {
            opportunityProfitabilityPreference = <ProfitabilityListPreferenceModel>{
                OpportunityId: opportunityId,
                ClientTabInfo: <TabInfo>{
                    ShowRelatedItems: false,
                    OpportunityIds: [],
                    ProductIds: []
                },
                DealTabInfo: <TabInfo>{
                    ShowRelatedItems: false,
                    OpportunityIds: [],
                    ProductIds: []
                }
            };

            if (!this.opportunityProfitabilityPreference) {
                this.opportunityProfitabilityPreference = [];
            }

            this.opportunityProfitabilityPreference.push(opportunityProfitabilityPreference);
        }      

        return Object.assign({}, opportunityProfitabilityPreference);
    }

    async setOpportunityProfitabilityPreferenceAsync(modifiedProfitabilityPreference: ProfitabilityListPreferenceModel): Promise<void> {
        if (!modifiedProfitabilityPreference) {
            throw new TypeError('A null or undefined modifiedProfitabilityPreference object was passed, ex: setOpportunityProfitabilityPreferenceAsync(modifiedProfitabilityPreference=null || undefined)');
        }

        await this.pullOpportunityPreferenceRecordAsync();
        if (this.opportunityProfitabilityPreference) {
            let opportunityProfitabilityPreferenceIndex: number = this.opportunityProfitabilityPreference.findIndex(x => x.OpportunityId === modifiedProfitabilityPreference.OpportunityId);
            this.opportunityProfitabilityPreference.splice(opportunityProfitabilityPreferenceIndex, 1, modifiedProfitabilityPreference);

            await this.userPropertyManager.setUserPropertyAsync<Array<ProfitabilityListPreferenceModel>>(this.opportunityProfitabilityPreferenceKey, this.opportunityProfitabilityPreference);
        }
    }

    async removeOpportunityProfitabilityPreferenceAsync(opportunityId: string): Promise<void> {
        if (!opportunityId) {
            throw new TypeError('A null or undefined opportunityId value was passed, ex: removeOpportunityProfitabilityPreferenceAsync(opportunityId=null || undefined)');
        }

        await this.pullOpportunityPreferenceRecordAsync();
        if (this.opportunityProfitabilityPreference) {
            let opportunityProfitabilityPreferenceIndex: number = this.opportunityProfitabilityPreference.findIndex(x => x.OpportunityId === opportunityId);
            this.opportunityProfitabilityPreference.splice(opportunityProfitabilityPreferenceIndex, 1);

            await this.userPropertyManager.setUserPropertyAsync<Array<ProfitabilityListPreferenceModel>>(this.opportunityProfitabilityPreferenceKey, this.opportunityProfitabilityPreference);
        }
    }

    private async pullOpportunityPreferenceRecordAsync(): Promise<void> {
        if (this.opportunityProfitabilityPreference) return;

        const opportunityProfitabilityPreferenceUserProperty: UserPropertyDto = await this.userPropertyManager.getUserPropertyAsync(this.opportunityProfitabilityPreferenceKey);

        if (!opportunityProfitabilityPreferenceUserProperty.PropertyValue) {
            this.opportunityProfitabilityPreference = new Array<ProfitabilityListPreferenceModel>();

        } else {
            this.opportunityProfitabilityPreference = JSON.parse(opportunityProfitabilityPreferenceUserProperty.PropertyValue)
                .ProfitabilityListPreference;
        }
    }
}
