import { SecurityObject } from "../common/security/security-object.model";
import { INavigationSection } from "../services/navigation.service";
import { UserModel } from "./user-model.model";

export class OpportunityDocumentSecurityModel {
    bothWriteAccessExists: boolean;
    bothReadAccessExists: boolean;
    uploadedDocWriteAccessExists: boolean;
    electronicDocsWriteAccessExists: boolean;
    uploadedDocReadAccessExists: boolean;
    electronicDocsReadAccessExists: boolean;
    uploadedDocsSecurityObjects: SecurityObject[];
    electronicDocsSecurityObjects: SecurityObject[];
    navigationSections: INavigationSection[];
    sectionName: string;
    primaryRoute: string;
    opportunityId: string;
    currentUser: UserModel;
    sectionIndex: number;
}
