export class ExceptionMessage {
    Code: string;
    Enum: string;
    Title: string;
    SimpleMessage: string;
    DetailedMessage: string;
    LogId: string;
    DetailedErrors?: IDetailedError[];
    TimeStamp: Date;
}

export interface IDetailedError {
    Message: string;
    Path: string[];
}
