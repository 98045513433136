import { ReplaySubject } from 'rxjs';
import { Observable } from 'rxjs';

export class CacheService {
    private static cache: CacheableObject[] = [];

    public static getOrSet<T>(key: string, observable: Observable<T>): Observable<T> {
        const cacheHit = CacheService.cache.find(x => x.key === key);
        if (cacheHit) {
            // the object has been found already, so just return the hot subject
            return cacheHit.subject;
        }

        // http://reactivex.io/documentation/subject.html
        // define the cacheable object with a new ReplaySubject
        const cacheMiss = <CacheableObject>{ key: key, subject: new ReplaySubject<T>() };
        CacheService.cache.push(cacheMiss);

        // subscribe the provided observable to this subject to prime both.
        observable.subscribe(cacheMiss.subject);

        // return the hot subject
        return cacheMiss.subject;
    }

    public static clearCache() {
        if (!CacheService.cache || !CacheService.cache.length)
            return;

        // for all the items, mark their subjects as complete to end the observable contracts
        for (let cacheItem of CacheService.cache) {
            if (cacheItem.subject) {
                cacheItem.subject.complete();
            }
        }

        // then start over
        CacheService.cache = [];
    }

    public static clearCacheById(id: string) {
        if (!CacheService.cache || !CacheService.cache.length)
            return;

        const itemIndex = CacheService.cache.findIndex(x => x.key == id);
        CacheService.cache.splice(itemIndex, 1);
    }
}

interface CacheableObject {
    key: string;
    subject: any;
}
