import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { Observable } from 'rxjs';
import { map, mergeMap } from "rxjs/operators";
import { AppSettings } from '../../../app.globals';
import { UnificationAPIService } from '../../services/unification.api.service';
import { CheckFileInfoResponse } from "./wopi-action.model";

const ExcelSpreadsheetContentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const WordDocumentContentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
const PowerPointPresentationContentType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';

@Injectable()
export class WopiAPIService {
    constructor(private baseService: UnificationAPIService) {
    }

    canEditInWopiByDocumentOutputTypeId(documentOutputTypeId: string): boolean {
        return documentOutputTypeId == '2cbce11f-2fc9-4f37-a00f-776c226582b4' // .docx
            || documentOutputTypeId == 'cab513ae-28fa-4caa-82df-d010db69e2b8' // .xlsx
            || documentOutputTypeId == '8336d724-82c4-494b-853b-ad71d8b3a005';// .pptx
    }

    canEditInWopiByFileExtension(extension: string): boolean {
        if (extension?.startsWith(".")) {
            extension = extension.substring(1);
        }
        return extension == "docx" || extension == "xlsx" || extension == 'pptx' ;
    }

    getWopiUrl(action: string, fileId: string): Observable<string> {
        const url = `${this.baseService.complexApiURL()}wopi/GetActionUrl/${action}/${fileId}`;
        return this.baseService.get(url);
    }

    getActionUrl(url: string): Observable<any> {
        return this.baseService.get(url);
    }

    getWopiFileMetadata(fileId: string): Observable<CheckFileInfoResponse> {
        const url = `${this.baseService.complexApiURL()}wopi/GetWopiFileMetadata/${fileId}`;
        return this.baseService.get(url);
    }

    checkForBakerHillDomain(): boolean {
        return this.baseService.complexApiURL().toLowerCase().indexOf('http://localhost') == -1;
    }

    getWopiAuthentication(): any {
        return {
            AccessToken: this.baseService.authToken,
            AccessTokenTTL: Date.parse(this.baseService.tokenExpiresAt)
        }
    }

    getWopiEditorUrl(fileId: string, accessToken: string): string {
        return AppSettings.API_BASE_URL + `WopiEditor/Index?fileid=${fileId}&token=${accessToken}&ttl=${Date.parse(this.baseService.tokenExpiresAt)}`;
    }

    getAnalysisDocumentDownloadUrl(id: string): Observable<string> {
        const url = this.baseService.complexApiURL() + 'wopi/GetAnalysisDocumentDownloadUrl/' + id;
        return this.baseService.get(url);
    }

    getUploadVisibilityValue() {
        let apiUrl: string = this.baseService.apiURL() + `SystemProperties/?$filter=Name eq 'UploadDownloadAnalysis'`;
        return this.baseService.get(apiUrl).pipe(map((result: any) => result.value));
    }

    getAnalysisTemplateDocumentDownloadUrl(id: string): Observable<string> {
        const url = this.baseService.complexApiURL() + 'wopi/GetAnalysisTemplateDocumentDownloadUrl/' + id;
        return this.baseService.get(url);
    }

    isContentTypeWopiEditable(contentType: string) {
        return contentType == WordDocumentContentType || contentType == ExcelSpreadsheetContentType || contentType == PowerPointPresentationContentType;
    }


    openEditorInNewWindow(wopiFileId: string): Subscription {
        return this.getWopiUrl("edit", wopiFileId)
            .pipe(
                mergeMap(wopiUrl => { return this.getActionUrl(wopiUrl) })
            )
            .subscribe((data: any) => {
                let url = this.getWopiEditorUrl(wopiFileId, data.AccessToken);
                window.open(url);
            });

    }

    uploadAnalysisDocument(id: string, file: any, dealId: string) {
        const url = `${this.baseService.complexApiURL()}wopi/UploadAnalysisDocument/`;
        let data =
        {
            Id: id,
            DealId: dealId,
            SerializedData: file
        };

        return this.baseService.post(url, data);
    }

    uploadAnalysisTemplateDocument(id: string, file: any) {
        const url = this.baseService.complexApiURL() + 'wopi/UploadAnalysisTemplateDocument';
        let data =
        {
            Id: id,
            SerializedData: file
        };

        return this.baseService.post(url, data);
    }

}
