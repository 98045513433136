import {lastValueFrom, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {AppFunctions} from './app.functions';
import {ParserHelper} from './controls/helpers/parsers.helper';
import {Cacheable} from './decorators/cacheable.decorator';
import {UnificationAPIService} from './services/unification.api.service';
import {Injectable} from "@angular/core";

@Injectable()
export class SystemPropertyAPIService extends UnificationAPIService {
    public getBoolean(name: string): Observable<boolean> {
        return this.getValue(name)
            .pipe(
                map((result: string) => {
                    return AppFunctions.toBoolean(result);
                })
            );
    }

    public getSystemPropertyKeyValue(systemPropertyNames: Array<string>): Observable<IdNameValue[]> {
        return this.ensureAllSystemProperties()
            .pipe(map(
                (allSystemProperties: IdNameValue[]) => {
                    let hasPropertyLength: any = [];
                    const systemProperties = allSystemProperties.filter(x => systemPropertyNames.includes(x.Name));
                    for (let property of systemPropertyNames) {
                        // check system property condition individually
                        hasPropertyLength = systemProperties.find(x => x.Name == property);
                        if (typeof hasPropertyLength == "undefined") {
                            throw (`The requested system property (${property}) is not found in the list of all system properties.`);
                        }
                    }
                    return systemProperties;
                })
            );
    }

    public async getBooleanAsync(name: string): Promise<boolean> {
        return lastValueFrom(this.getBoolean(name));
    }

    public getInteger(name: string): Observable<number> {
        return this.getValue(name)
            .pipe(
                map((result: string) => {
                    if (ParserHelper.IsNullOrWhitespace(result))
                        throw `The requested system property (${name}) is required but does not have a value.`;
                    return ParserHelper.parseInteger(result);
                })
            );
    }

    public async getIntegerAsync(name: string): Promise<number> {
        return this.getInteger(name).toPromise();
    }

    public getString(name: string): Observable<string> {
        return this.getValue(name);
    }

    private getValue(name: string): Observable<string> {
        return this.ensureAllSystemProperties()
            .pipe(map(
                (systemProperties: IdNameValue[]) => {
                    // for now, use the enum's name as the string to compare against.
                    const systemProperty = systemProperties.find(x => x.Name == name);
                    if (systemProperty == null) {
                        throw (`The requested system property (${name}) is not found in the list of all system properties.`);
                    }
                    return systemProperty.Value;
                })
            );
    }

    @Cacheable()
    private ensureAllSystemProperties(): Observable<IdNameValue[]> {
        const allSystemPropertiesURL = `${this.apiURL()}SystemProperties/?$filter=IsActive eq true&$select=Id,Name,Value&$orderby=Name`;
        return this.get(allSystemPropertiesURL)
            .pipe(
                map((x: any) => x.value.map((x: IdNameValue[]) => x)
                ));
    }
}

interface IdNameValue {
    Id: string;
    Name: string;
    Value: string;
}
