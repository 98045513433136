import { Injectable } from '@angular/core';
import { UnificationAPIService } from './unification.api.service';
import { Observable } from 'rxjs';

@Injectable()
export class DefaultHomePageService {
    private url: string = null;
    private readonly riskRatingName: string = "(riskratinglist//section:empty)";
    constructor(private baseService: UnificationAPIService) {
        this.url = this.baseService.complexApiURL() + 'user';
    }

    public getClientDefaultHomePage(): any {
        let landingPageUrls = [
            { key: 'Banker - Draft Applications', url: '/auth/banker/(bankerhome//section:empty)' },
            { key: 'CECL - Clients', url: '/auth/cecl/(ceclhome//section:clientsquery)' },
            { key: 'CECL - Opportunities', url: '/auth/cecl/(ceclhome//section:opportunitiesquery)' },
            { key: 'CECL - Products', url: '/auth/cecl/(ceclhome//section:productsquery)' },
            { key: 'CECL - Qualitative Factors', url: '/auth/cecl/(qualitativeFactorPoolList//section:empty))' },
            { key: 'CECL - Reports', url: '/auth/cecl/(reportshome//section:empty))' },
            { key: 'CECL - Statistics', url: '/auth/cecl/(ceclhome//section:ceclstatistics)' },
            { key: 'CECL - Risk Ratings', url: `/auth/cecl/${this.riskRatingName}` },
            { key: 'CECL - Tiers and Pools', url: '/auth/cecl/(cecltiersandpoolslist//section:empty))' },
            { key: 'CRM - Activities', url: '/auth/crm/(activitieshome//section:activitylist)' },
            { key: 'CRM - Clients', url: '/auth/crm/(clientshome//section:clientsquery)' },
            { key: 'CRM - Collateral', url: '/auth/crm/(lendinghome//section:collateralsquery)' },
            { key: 'CRM - Groups', url: '/auth/crm/(clientshome//section:groupquery)' },
            { key: 'CRM - Referrals', url: '/auth/crm/(clientshome//section:referralquery)' },
            { key: 'CRM - Reports', url: '/auth/crm/(reportshome//section:empty))' },
            { key: 'CRM - Risk Ratings', url: `/auth/crm/${this.riskRatingName}` },
            { key: 'CRM - Tracking Items', url: '/auth/crm/(clientshome//section:trackingitemsquery)' },
            { key: 'Lending - Clients', url: '/auth/lending/(lendinghome//section:clientsquery)' },
            { key: 'Lending - Collateral', url: '/auth/lending/(lendinghome//section:collateralsquery)' },
            { key: 'Lending - Groups', url: '/auth/lending/(lendinghome//section:groupquery)' },
            { key: 'Lending - Opportunities', url: '/auth/lending/(lendinghome//section:opportunitiesquery)' },
            { key: 'Lending - Products', url: '/auth/lending/(lendinghome//section:productsquery)' },
            { key: 'Lending - Risk Ratings', url: `/auth/lending/${this.riskRatingName}` },
            { key: 'Lending - Tracking Items', url: '/auth/lending/(lendinghome//section:trackingitemsquery)' },
            { key: 'Portfolio - Clients', url: '/auth/portfolio/(portfoliohome//section:clientsquery)' },
            { key: 'Portfolio - Collateral', url: '/auth/portfolio/(lendinghome//section:collateralsquery)' },
            { key: 'Portfolio - Covenant Report', url: '/auth/portfolio/(covenantreport//section:empty))' },
            { key: 'Portfolio - CRA Report', url: '/auth/portfolio/(cradatareport//section:empty))' },
            { key: 'Portfolio - Groups', url: '/auth/portfolio/(portfoliohome//section:groupquery)' },
            { key: 'Portfolio - HMDA Report', url: '/auth/portfolio/(hmdadatareport//section:empty))' },
            { key: 'Portfolio - SBLR Report', url: '/auth/portfolio/(sblrdatareport//section:empty))' },
            { key: 'Portfolio - Products', url: '/auth/portfolio/(portfoliohome//section:productsquery)' },
            { key: 'Portfolio - Reports', url: '/auth/portfolio/(reportshome//section:empty))' },
            { key: 'Portfolio - Risk Ratings', url: `/auth/portfolio/${this.riskRatingName}` },
            { key: 'Portfolio - Tracking Items', url: '/auth/portfolio/(portfoliohome//section:trackingitemsquery)' },
        ];
        return landingPageUrls;
    }

    public getDefaultHomePage(): Observable<any> {
        let url = this.url + '/GetDefaultHomePage';
        return this.baseService.get(url);
    }
}
