import { Injectable } from "@angular/core";
import { UnificationAPIService } from "./unification.api.service";
import { UserPropertyDto, UserPropertyManager } from "./user-property-manager.service";

export class ChartDetail {
    chartId: string;
    chartName: string;
    moduleName: string;
    isChartDisplay: boolean;
    isHideAll: boolean;
}

export class ChartPreferenceModel {
    chartInfoLending: Array<ChartDetail>;
    chartInfoCrm: Array<ChartDetail>;
    chartInfoPortfolio: Array<ChartDetail>;
}

@Injectable()
export class ChartPreferenceManager {
    private chartPreferenceKey: string = 'ChartPreference';
    protected chartPreference: ChartPreferenceModel;

    constructor(
        protected baseService: UnificationAPIService,
        protected userPropertyManager: UserPropertyManager
    ) {
    }

    async getChartPreferenceAsync(): Promise<ChartPreferenceModel> {
        // Ensure the global chartPreference variable is set before doing anything
        await this.pullChartPreferenceRecordAsync();

        return Object.assign({}, this.chartPreference);
    }

    async setChartPreferenceAsync(modifiedChartPreference: ChartPreferenceModel): Promise<void> {
        if (!modifiedChartPreference) {
            throw new TypeError('A null or undefined modifiedChartPreference object was passed, ex: setChartPreference(modifiedChartPreference=null || undefined)');
        }

        // Ensure the global chartPreference variable is set before doing anything
        await this.pullChartPreferenceRecordAsync();

        // Now that we've made sure we have the user's current chartPreference, update it with
        // the requested changes and save it.
        Object.assign(this.chartPreference, modifiedChartPreference);

        await this.userPropertyManager.setUserPropertyAsync<ChartPreferenceModel>(this.chartPreferenceKey, this.chartPreference);
    }

    private async pullChartPreferenceRecordAsync(): Promise<void> {
        if (this.chartPreference) return;

        const chartPreferenceUserProperty: UserPropertyDto = await this.userPropertyManager.getUserPropertyAsync(this.chartPreferenceKey);

        if (!chartPreferenceUserProperty.PropertyValue) {
            this.chartPreference = <ChartPreferenceModel>{
                chartInfoCrm: new Array<ChartDetail>(),
                chartInfoLending: new Array<ChartDetail>(),
                chartInfoPortfolio: new Array<ChartDetail>()
            };
        } else {
            this.chartPreference = JSON.parse(chartPreferenceUserProperty.PropertyValue)
        }
    }
}