import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription, throwError as observableThrowError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { AppSettingsModel } from '@shared/models/app-settings.model';
import { CacheService } from '@shared/services/cache-service';
import { AppSettings } from '../app.globals';
import { NotificationService } from './services/notification.service';
import { UnificationAPIService } from './services/unification.api.service';
import { AuthenticationSettingsModel } from './models/authentication-settings.model';
import { AuthorizationModel } from './authorization.model';
import { UserModel } from '@shared/models/user-model.model';
import { UserPermissions } from '@shared/common/security/user-permissions.model';
import { ExchangeLoginResultModel } from './models/exchange-login-user.model';

@Injectable()
export class AuthorizationService implements OnDestroy {
    isLoggedIn: boolean = true;
    private serviceSubscription: Subscription;

    constructor(
        private baseService: UnificationAPIService,
        private http: HttpClient,
        private notificationService: NotificationService) {

    }

    clearToken() {
        this.baseService.clearAuthToken();
    }

    login(model: LoginModel): Observable<any> {
        if (!AppSettings.API_BASE_URL) {
            this.notificationService.error('There is a problem with the API url');
            return observableThrowError(new Error('There is a problem with the API url'));
        }

        return this.authenticateUser(model)
            .pipe(
                tap((context: AuthorizationModel) => {
                    this.baseService.authToken = context.access_token;
                    this.baseService.refreshToken = context.refresh_token;
                    this.baseService.tokenExpiresIn = context.expires_in;
                    this.isLoggedIn = true;
                }));
    }

    loginWithToken(token: string, grantType: string = 'appaccess', browserFingerprint: string = null, verificationCode: string = ''): Observable<any> {
        if (!AppSettings.API_BASE_URL) {
            this.notificationService.error('There is a problem with the API url');
            return observableThrowError(new Error('There is a problem with the API url'));
        }
        let body = `grant_type=${grantType}&scope=nextgenui`;
        let url = `${AppSettings.API_BASE_URL}token`;
        let tokenCode = `code=${token}`;

        if (grantType === 'samlaccess')
            tokenCode = `fingerprint=${browserFingerprint}&code=${verificationCode}&samlcode=${token}`;

        body += `&${tokenCode}`;

        return this.baseService.post(url, body, this.baseService.formHeaders)
            .pipe(tap((context: any) => {
                this.baseService.authToken = context.access_token;
                this.baseService.refreshToken = context.refresh_token;
                this.baseService.tokenExpiresIn = context.expires_in;
                this.baseService.startLogoutInterval();
                this.isLoggedIn = true;
            }));
    }

    authenticateUser(model: LoginModel): Observable<AuthorizationModel> {
        let body = `grant_type=nextgen&username=${encodeURIComponent(model.email)}&password=${encodeURIComponent(model.password)}&fingerprint=${model.fingerprint}&code=${model.code}&scope=nextgenui`;

        return this.baseService.post<AuthorizationModel>(`${AppSettings.API_BASE_URL}token`, body, this.baseService.formHeaders)
    }

    authenticateUserWithAzureToken(grantType: string,
                                   token: string,
                                   financialInstitutionName: string,
                                   reason: string,
                                   caseNumber: string,
                                   userId: string): Observable<AuthorizationModel> {
        const body = `grant_type=${grantType}&financialinstitution=${financialInstitutionName}&code=${token}&userId=${userId ?? ''}&scope=nextgenui&reason=${encodeURIComponent(reason ?? '')}&caseNumber=${encodeURIComponent(caseNumber ?? '')}`;
        return this.baseService.post<AuthorizationModel>(`${AppSettings.API_BASE_URL}token`, body, this.baseService.formHeaders)
            .pipe(
                tap((context: AuthorizationModel) => {
                    this.baseService.authToken = context.access_token;
                    this.baseService.refreshToken = context.refresh_token;
                    this.baseService.tokenExpiresIn = context.expires_in;
                    this.isLoggedIn = true;
                }));
    }

    getAuthenticationSettings(email: string): Observable<AuthenticationSettingsModel> {
        let url: string = `${AppSettings.API_BASE_URL}api/authentication/settings?email=${email}`;
        return this.baseService.get<AuthenticationSettingsModel>(url);
    }

    getUser(): Observable<any> {
        return this.baseService.get(`${AppSettings.API_BASE_URL}api/user/GetCurrentUser`);
    }

    getAuthenticatedInfo(): Observable<AuthenticatedInfoModel> {
        let apiUrl: string = this.baseService.complexApiURL() + 'User/GetAuthenticatedInfo';

        return this.baseService.get(apiUrl);
    }

    logOutWithclearUserCache(): Observable<any> {
        CacheService.clearCache();
        let url: string = `${AppSettings.API_BASE_URL}api/authentication/logout`;
        return this.baseService.get(url);
    }

    loginToExchange(password: string): Observable<ExchangeLoginResultModel> {
        const user = {
            CalendarPassword: password
        }
        const url: string = `${AppSettings.API_BASE_URL}api/user/ValidateExchangeLogin`;
        return this.baseService.post(url, JSON.stringify(user)).pipe(map((result: ExchangeLoginResultModel) => {
            if (!result.IsSuccess && result.Errors !== null) {
                result.Message = '';
                for (const error of result.Errors) {
                    result.Message += `${error.Message}<br>`;
                }
                return result;
            }
            return result;
        }));
    }

    getAppSettings(): Observable<AppSettingsModel> {
        let url: string = `${AppSettings.API_BASE_URL}api/appsettings`;
        return this.baseService.get<AppSettingsModel>(url);
    }

    getCeclFlag(checkUserFlag: boolean) {
        let url: string = `${AppSettings.API_BASE_URL}api/cecl/CheckIfCeclEnabled/${checkUserFlag}`;
        return this.baseService.get(url);
    }

    getScoreCardSystemPropertyValue(): Observable<any> {
        var systemPropertyName = 'EnableRiskRatings';
        const sysPropUrl = `${this.baseService.complexApiURL()}SystemPropertyByName/${systemPropertyName}`;
        return this.baseService.get(sysPropUrl);
    }

    getUserFlagca() {
        var currentUser = JSON.parse(this.baseService.currentUser);
        return currentUser.IsClientAdvisorEnabled;
    }

    canUserAccessReports() {
        var currentUser = JSON.parse(this.baseService.currentUser);
        return (currentUser.IsExceptionTrackingEnabled || currentUser.IsCustomerRelationshipManagementEnabled || currentUser.IsPortfolioRiskManagementsEnabled || currentUser.IsBusinessLoanOriginationEnabled || currentUser.IsConsumerLoanOriginationEnabled || currentUser.IsPortfolioRiskManagementEnabled);
    }

    storeAppSettings(appSettings) {
        this.baseService.appSettings = JSON.stringify(appSettings);
    }

    storeUser(user) {
        this.baseService.currentUser = JSON.stringify(user);
    }

    getCurrentUser(): any {
        return JSON.parse(this.baseService.currentUser);
    }

    ngOnDestroy() {
        if (this.serviceSubscription) {
            this.serviceSubscription.unsubscribe();
        }
    }

    logout() {
        this.isLoggedIn = false;
    }

    loginAsProxy(proxyKey: string) {
        if (!AppSettings.API_BASE_URL) {
            this.notificationService.error('There is a problem with the API url');
            return observableThrowError(new Error('There is a problem with the API url'));
        }
        let body = `code=${proxyKey}&grant_type=proxy`;
        let url = `${AppSettings.API_BASE_URL}token`;
        return this.baseService.post(url, body, this.baseService.formHeaders)
            .pipe(tap((context: any) => {
                this.baseService.authToken = context.access_token;
                this.baseService.refreshToken = context.refresh_token;
                this.baseService.tokenExpiresIn = context.expires_in;
                this.baseService.startLogoutInterval();
                this.isLoggedIn = true;
            }));
    }

    revertProxyLogin(proxyKey: string) {
        if (!AppSettings.API_BASE_URL) {
            this.notificationService.error('There is a problem with the API url');
            return observableThrowError(new Error('There is a problem with the API url'));
        }
        let body = `code=${proxyKey}&grant_type=revertproxy`;
        let url = `${AppSettings.API_BASE_URL}token`;
        return this.baseService.post(url, body, this.baseService.formHeaders)
            .pipe(tap((context: any) => {
                this.baseService.authToken = context.access_token;
                this.baseService.refreshToken = context.refresh_token;
                this.baseService.tokenExpiresIn = context.expires_in;
                this.baseService.startLogoutInterval();
                this.isLoggedIn = true;
            }));
    }

    getNadaPermission(calledFrom: string): Observable<any> {
        let url: string = `${AppSettings.API_BASE_URL}api/NADACollateralValuations/CanPullNadaValuation/${calledFrom}`;
        return this.baseService.get(url);
    }
}

export class LoginModel {
    email: string = null;
    password: string = null;
    fingerprint: string = null;
    code: string = '';
    token: string = null;
}

export class AuthenticatedInfoModel {
    User: UserModel = new UserModel();
    UserPermissions: UserPermissions = new UserPermissions();
    UserSectionListPreference: string = null;
    DefaultHomePage: string = null;
    EnableOverrideNextGenApiUri: boolean = false;
    OverrideNextGenApiUri: string = null;
    DefaultSection: string = null;
}
