import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthorizationService } from '@shared/authorization.service';
import { ParserHelper } from '@shared/controls/helpers/parsers.helper';
import { ApplicationSecurityService } from '@shared/services/application-security.service';
import { CacheService } from '@shared/services/cache-service';
import { DefaultHomePageService } from '@shared/services/default-homepage.service';
import { NotificationService } from '@shared/services/notification.service';
import { PubSubService } from '@shared/services/pubsub.service';
import { UnificationAPIService } from '@shared/services/unification.api.service';
import { SystemPropertyAPIService } from '@shared/system-property.api.service';
import { ChartPreferenceManager } from './services/chart-preference-manager.service';
import { QueryListPreferenceManager } from './services/query-list-preference-manager.service';
import { TablePreferenceManager, } from './services/table-preference-manager.service';
import { TrackingItemListPreferenceManager } from './services/tracking-item-list-preference-manager.service';
import { UserPreferenceManager } from './services/user-preference-manager.service';
import { UserPropertyManager } from './services/user-property-manager.service';
import { TreePreferenceManager, } from './services/tree-preference-manager.service';
import { DefaultOpportunitySectionsService } from './services/default-opportunity-sections.service';
import { OpportunityProfitabilityPreferenceManager } from './services/opportunity-profitability-preference-manager.service';
import { WopiAPIService } from './common/wopi/wopi.api.service';

@NgModule({
    imports: [],
    exports: [
        // CoreModule should be pure and have no exports
    ],
    declarations: [
        // CoreModule should be pure and have no declarations
    ],
    providers: [
        // singleton services the application uses
        AuthorizationService,
        NotificationService,
        PubSubService,
        CacheService,
        UnificationAPIService,
        ParserHelper,
        ApplicationSecurityService,
        SystemPropertyAPIService,
        DefaultHomePageService,
        UserPropertyManager,
        TablePreferenceManager,
        QueryListPreferenceManager,
        UserPreferenceManager,
        TrackingItemListPreferenceManager,
        ChartPreferenceManager,
        TreePreferenceManager,
        OpportunityProfitabilityPreferenceManager,
        DefaultOpportunitySectionsService,
        WopiAPIService
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error(
                'CoreModule is already loaded. Import it in the AppModule only');
        }
    }
}
