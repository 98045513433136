import { ErrorHandler, NgModule } from '@angular/core';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { AppInsightsService } from 'shared/services/app-insights.service';


export class NextGenErrorHandler extends ApplicationinsightsAngularpluginErrorService {

    handleError(error: any): void {
        console.error(error);
        super.handleError(error);
    }
}

@NgModule({
    providers: [
        AppInsightsService,
        {
            provide: ErrorHandler,
            useClass: NextGenErrorHandler,
        },
    ],
})
export class InsightsModule {
    constructor(private insights: AppInsightsService) {
    }
}
