export class UserModel {
    Id: string = null;
    Login: string = null;
    FullName: string = null;
    Email: string = null;
    FinancialInstitutionDomainName: string = null;
    FinancialInstitutionName: string = null;
    ChangePasswordMessage: string = null;
    IsClientAdvisorEnabled: boolean = false;
    IsCustomerRelationshipManagementEnabled: boolean = false;
    IsUserBankerEnabled: boolean = false;
    IsFinancialInstitutionBankerEnabled: boolean = false;
    IsBusinessLoanOriginationEnabled: boolean = false;
    IsPortfolioRiskManagementsEnabled: boolean = false;
    IsStatementAnalyzerEnabled: boolean = false;
    IsExceptionTrackingEnabled: boolean = false;
    IsPullCreditBureauReport2Enabled: boolean = false;
    IsSysAdmin: boolean = false;
    IsSecurityAdmin: boolean = false;
    IsBHCAdmin: boolean = false;
    IsSingleSignOn: boolean = false;
    AssignedBusinessUnit: string = null;
    CanEditReportTemplates: boolean = false;
    CanDeleteReportTemplates: boolean = false;
    FinancialInstitutionId: string = null;
    FinancialInstitutionTheme: string = null;
    SuperWriter: boolean = false;
    SuperReader: boolean = false;
    IsEnableExchangeLoginScreen: boolean = false;
    IsSkippedExchangeLoginScreen: boolean = false;
    IsUserExchangeEnable: boolean = false;
    IsAppExchangeEnabled: boolean = false;
    AllowResetUserPassword: boolean = false;
    IsOverrideCreditPolicyViolations: boolean = false;
    IsConsumerLoanOriginationEnabled: boolean = false;
    IsCeclEnabled: boolean = false;
    IsChangeIdentityEnabled: boolean = false;
    IsClientPortalEnabled: boolean = false;
    IsMultiFactorAuthenticationEnabled: boolean = false;
    IsExchangeOAuthEnabled: boolean = false;
    IsBHCA: boolean = false;
    ShowSensitiveIndicator: boolean = false;
    FlagSuperApprover: boolean = false;
    IsProcessTaxReturnEnabled: boolean = false;
}
