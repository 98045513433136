import { Injectable } from "@angular/core";
import { UnificationAPIService } from "./unification.api.service";
import { UserPropertyDto, UserPropertyManager } from "./user-property-manager.service";

export class TrackingItemListPreferenceModel {
    GroupByTrackingFamilyView: boolean;
}

@Injectable()
export class TrackingItemListPreferenceManager {
    private trackingItemListPreferenceKey: string = 'TrackingItemListPreference';
    protected trackingItemListPreference: TrackingItemListPreferenceModel;

    constructor(
        protected baseService: UnificationAPIService,
        protected userPropertyManager: UserPropertyManager
    ) {
    }

    async getTrackingItemListPreferenceAsync(): Promise<TrackingItemListPreferenceModel> {
        // Ensure the global trackingItemListPreference variable is set before doing anything
        await this.pullTrackingItemListPreferenceRecordAsync();

        return Object.assign({}, this.trackingItemListPreference);
    }

    async setTrackingItemListPreferenceAsync(modifiedTrackingItemListPreference: TrackingItemListPreferenceModel): Promise<void> {
        if (!modifiedTrackingItemListPreference) {
            throw new TypeError('A null or undefined modifiedTrackingItemListPreference object was passed, ex: setTrackingItemListPreference(modifiedTrackingItemListPreference=null || undefined)');
        }

        // Ensure the global trackingItemListPreference variable is set before doing anything
        await this.pullTrackingItemListPreferenceRecordAsync();

        // Now that we've made sure we have the user's current trackingItemListPreference, update it with
        // the requested changes and save it.
        Object.assign(this.trackingItemListPreference, modifiedTrackingItemListPreference);

        await this.userPropertyManager.setUserPropertyAsync<TrackingItemListPreferenceModel>(this.trackingItemListPreferenceKey, this.trackingItemListPreference);
    }

    private async pullTrackingItemListPreferenceRecordAsync(): Promise<void> {
        if (this.trackingItemListPreference) return;

        const trackingItemListPreferenceUserProperty: UserPropertyDto = await this.userPropertyManager.getUserPropertyAsync(this.trackingItemListPreferenceKey);

        if (!trackingItemListPreferenceUserProperty.PropertyValue) {
            this.trackingItemListPreference = <TrackingItemListPreferenceModel>{
                GroupByTrackingFamilyView: false
            };
        } else {
            this.trackingItemListPreference = JSON.parse(trackingItemListPreferenceUserProperty.PropertyValue)
        }
    }
}