import { Injectable } from "@angular/core";
import { AppFunctions } from "../app.functions";
import { UnificationAPIService } from "./unification.api.service";
import { UserPropertyDto, UserPropertyManager } from "./user-property-manager.service";

export class UserPreferenceModel {
    NotificationPreference: string;
    HomePagePreference: string;
    NumberOfRowsPreference: number;
    TreeExpandPreference: boolean;
    WatermarkText: string;
    TradeLinePreference: string;
    SectionsPreference: string;
}

@Injectable()
export class UserPreferenceManager {
    private userPreferenceKey: string = 'UserPreference';
    protected userPreference: UserPreferenceModel;

    constructor(
        protected baseService: UnificationAPIService,
        protected userPropertyManager: UserPropertyManager
    ) {
    }

    async getUserPreferenceAsync(): Promise<UserPreferenceModel> {
        // Ensure the global userPreference variable is set before doing anything
        await this.pullUserPreferenceRecordAsync();

        return Object.assign({}, this.userPreference);
    }

    async setUserPreferenceAsync(modifiedUserPreference: UserPreferenceModel): Promise<void> {
        if (!modifiedUserPreference) {
            throw new TypeError('A null or undefined modifiedUserPreference object was passed, ex: setUserPreference(modifiedUserPreference=null || undefined)');
        }

        // Ensure the global userPreference variable is set before doing anything
        await this.pullUserPreferenceRecordAsync();

        // Now that we've made sure we have the user's current userPreference, update it with
        // the requested changes and save it.
        Object.assign(this.userPreference, modifiedUserPreference);

        await this.userPropertyManager.setUserPropertyAsync<UserPreferenceModel>(this.userPreferenceKey, this.userPreference);
    }

    private async pullUserPreferenceRecordAsync(): Promise<void> {
        if (this.userPreference) {
            return;
        }

        const userPreferenceUserProperty: UserPropertyDto = await this.userPropertyManager.getUserPropertyAsync(this.userPreferenceKey);

        if (!userPreferenceUserProperty.PropertyValue) {
            this.userPreference = <UserPreferenceModel>{
                HomePagePreference: null,
                NotificationPreference: '0',
                NumberOfRowsPreference: 10,
                TreeExpandPreference: false,
                TradeLinePreference: 'Show All Tradelines',
                SectionsPreference: 'Activities'
            };
        } else {
            this.userPreference = JSON.parse(userPreferenceUserProperty.PropertyValue)
        }

        if (this.userPreference.NumberOfRowsPreference === null)
            this.userPreference.NumberOfRowsPreference = 10;

        if (AppFunctions.IsNullOrWhiteSpace(this.userPreference.SectionsPreference))
            this.userPreference.SectionsPreference = 'Activities';
    }
}